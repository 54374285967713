'use client';

import * as Sentry from '@sentry/nextjs';
import '@americastestkitchen/ui/styles.css';
import '@americastestkitchen/mise/styles.css';
import 'mise/src/styles/styles.scss';
import { ErrorScreen } from './_components/ErrorScreen';
import { useEffect } from 'react';

export default function GlobalError({ error }: { error: Error }) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return (
    <html>
      <body className="atk">
        <ErrorScreen errorCode={500} />
      </body>
    </html>
  );
}
